import styled from 'styled-components/macro'
import { Checkbox, Stack } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const StackMotion = motion(Stack)

export const StyleMessengerBlock = styled.div`
  position: relative;
`

export const StyleNumUnread = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: ${(props) => (props.numUnread > 9 ? 'translate(-15%, -52.5%)' : 'translate(-30%, -52%)')};
  font-size: 15px;
  color: red;
  cursor: pointer;
`

export const DropdownStackParent = styled(StackMotion)`
  width: 60%;
  height: 100px;
  position: absolute;
  top: 48px;
  left: 5%;
  z-index: 1000;
  overflow: hidden;
`

export const DropdownStack = styled(StackMotion)`
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgb(212, 212, 212);
  padding: 10px 20px 10px 11px;
  spacing: 3;
  align: stretch;
  border-radius: 0 0 5px 5px;
`

export const DropdownCheckBox = styled(Checkbox)`
  display: flex;
  gap: 3;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`
