import { __ } from 'i18n';
import { html } from "lit";
import { STATUSES } from '../constants.js';

export default  (el, model) => {
   const show = model.presence.get('show') || 'offline';
    let classes, color;
    if (show === 'online') {
        [classes, color] = ['fa fa-circle', 'chat-status-online'];
    } else if (show === 'dnd') {
        [classes, color] =  ['fa fa-minus-circle', 'chat-status-busy'];
    } else if (show === 'away') {
        [classes, color] =  ['fa fa-circle', 'chat-status-away'];
    } else {
        [classes, color] = ['fa fa-circle', 'subdued-color'];
    }
   const desc_status = STATUSES[show];
   const num_unread = model.get('num_unread') || 0;
   const display_name = model.getDisplayName();
   const i18n_chat = __('Click to chat with %1$s', display_name, model.get('jid'));
   const showFriendMarker = (jid) => {
      const event = new CustomEvent('custom-chat-show_on_map', {
         detail: {
            jid: jid,
         }});
      window.dispatchEvent(event);
   }
   const most_recent_message = model.get('most_recent_message');
   const most_recent_message_timestamp = model.get('most_recent_message_timestamp');


   const parseAndFormatDate = (text) => {
      const isValidDate = (text_input) => {
         try {
            // Simulate JavaScript's Date parsing behavior using eval
            // (Caution: eval can be a security risk, use with care)
            const date = new Date(text_input);
         
            // Check if the date is not "Invalid Date" (default for invalid input)
            // AND if the date represents the same time as it was originally parsed
            // (to handle cases like "2024-13-01" which technically creates a valid date object but not for December 1st, 2024)
            return date.toString() !== 'Invalid Date' && date.getTime() === new Date(date.getTime()).getTime();
         } catch (error) {
            return false;
         }
      }

      if (!isValidDate(text)) {
         return '';
      }

      const date = new Date(text);
      const today = new Date();
      const dateStr = date.toLocaleDateString();
      if (today.toLocaleDateString() !== dateStr) {
         return date.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit'
         });
      }

      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;

      return `${formattedHours}:${minutes} ${ampm}`;
   }

   const hasMsg = () => {
      if (most_recent_message === undefined) {
         return ''
      } 

      return num_unread ? 'new-message-indicator' : 'roster-info-border'
   }

   const msgDate = parseAndFormatDate(most_recent_message_timestamp);

   return html`<a class="list-item-link cbox-list-item open-chat in-animation ${ num_unread ? 'unread-msgs' : '' }" title="${i18n_chat}" href="#" @click="${el.openChat}" style="display:flex;align-items:center;font-size:large;width:100%;line-height:16px;padding:3px"><converse-avatar data-nickname="${model?.attributes?.nickname}" .data="${model.vcard?.attributes}" nonce="${model.vcard?.get('vcard_updated')}" borderColorVar="${color}" height="60" width="60" style="flex-shrink:0;margin-right:10px" where="roster_item" @click="${(ev) => {
               ev.preventDefault()
               ev.stopPropagation()
               showFriendMarker(model.get('jid'))
            }}" title="Click to show on map"></converse-avatar><div style="flex:1;display:flex;flex-direction:column;overflow:hidden"><div style="display:flex;justify-content:space-between;align-items:baseline;margin-bottom:10px;height:20px"><span class="roster-info-border" style="width:${ hasMsg() === '' ? '100%' : '70%'};overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:16px;font-weight:600;padding:2px 7.5px">${display_name} </span>${ 
                  most_recent_message !== undefined 
                     ? html`<span class="roster-info-border" style="font-size:16px;margin-bottom:-2px;font-weight:400;padding:2px 4px;width:28%;justify-content:center;display:flex">${msgDate}</span>` : ''
               }</div>${ 
               most_recent_message !== undefined 
                  ? html`<div class="${ hasMsg() }" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:16px;padding:2px 7.5px;font-weight:400;border-width:${ num_unread ? '1.5px' : '1px' }">${most_recent_message}</div>` : ''
            }</div></a>`;
}
