import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Button, Center, Image, Spinner, Stack, Text, useDisclosure
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import {
  getProfileItem,
  setProfileItemId,
  showCurrentChatFriendMarkerCoordinate,
  usersSelectors
} from 'store'
import { NOTIFICATION_TYPE, PENDING, SUCCEEDED } from 'utils/constants'
import { generateDifferenceByDay } from 'utils/helpers'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import { ConnectRequestModal } from './ConnectRequestModal'

export const ConnectionsRequest = ({
  notifications,
  // isAcceptLoading,
  // isRejectLoading,
  handleNotificationButton,
  getFriendsNotificationsStatus,
  getBlockedRequestStatus,
  selectId
}) => {
  const dispatch = useDispatch()
  const {
    getProfileItemStatus,
    profileItemId,
    profileItem,
    postMakeNotificationSeenStatus
  } = useSelector(usersSelectors)

  // Obsolete since profile can be viewed on notification panel
  const showFriendHandler = (item) => {
    const [lng, lat] = item?.coordinates
    const senderId = item.sender_id

    let coord = {
      coord: [
        Number(lng.toFixed(6)),
        Number(lat.toFixed(6))
      ],
      isStatic: true
    }
    dispatch(showCurrentChatFriendMarkerCoordinate(coord))
    document.querySelector('#back-button').click()
    localStorage.setItem('openProfileAfterAnimation', senderId)
  }

  return (
    <Stack
      spacing="55px"
      id="connection-request-list"
      // New Attributes for new design
      paddingBottom={notifications.length > 3 ? '5px' : null}
      marginLeft="5px"
      marginRight="5px"
      sx={{
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px'
      }}
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '10px',
          display: 'block'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '24px',
          background: 'rgba(255, 215, 0, 1)'
        }
      }}
    >
      {notifications.map((item) => (
        <Stack key={uuid()} id={item?.sender_id}>
          <Box
            key={uuid()}
            cursor="pointer"
            onClick={(e) => {
              if (localStorage.getItem('is-notif-modal-open') === 'false'
              && postMakeNotificationSeenStatus !== PENDING) {
                try {
                  document.querySelector('#force-close-blog-drawer').click()
                } catch {
                  //
                }
                if (item?.sender_id === profileItemId) {
                  dispatch(getProfileItem(item?.sender_id))
                } else {
                  dispatch(getProfileItem.pending())
                  dispatch(setProfileItemId(item?.sender_id))
                }
                localStorage.setItem('friendReqID', item?.friend_request_id)
                localStorage.setItem('id', item?.sender_id)
                setTimeout(() => document.getElementById(item?.sender_id).scrollIntoView({
                  block: 'nearest'
                }), 50)
              }
            }}
            sx={{
              minWidth: '118px',
              marginTop: '5px'
            }}
            data-name="notificationItem"
            position="relative"
            pointerEvents={(getProfileItemStatus === PENDING && profileItemId) || item?.sender_id === profileItemId > 0 ? 'none' : 'auto'}
          >
            {getProfileItemStatus === PENDING && item?.sender_id === profileItemId
              ? (
                <Center position="absolute">
                  <Spinner
                    key={uuid()}
                    width="60px"
                    height="60px"
                    position="sticky"
                    marginLeft="25px"
                    marginTop="25px"
                    zIndex={5}
                  />
                </Center>
              ) : null }
            <Image
              key={uuid()}
              width="108px"
              height="108px"
              opacity={
                getProfileItemStatus === PENDING
                && item?.sender_id === profileItemId ? 0.7 : 1
              }
              objectFit="cover"
              src={item.profile_picture ?? ImagesPng.DefaultImage}
              alt={item?.title}
              borderRadius="5px"
            />
          </Box>

          <ConnectRequestModal
            requestId={item?.sender_id}
            selectId={selectId}
            handleNotificationButton={handleNotificationButton}
            getProfileItemStatus={getProfileItemStatus}
            friendRequestID={item?.friend_request_id}
          />
        </Stack>
      ))}
    </Stack>
  )
}
