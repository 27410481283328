import styled from 'styled-components/macro'
import {
  ModalContent,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'

export const ModalContentStyled = styled(ModalContent)`
  width: 365px !important;
  padding: 0 !important;
  background: linear-gradient(to bottom, lightgray, gray) !important;
  border: 1px solid black;
  
  @media (max-width: 480px) and (max-height: 800px) {
    width: 85svw !important;
  }

  @media (max-width: 450px) and (max-height: 800px) {
    width: 84.5svw !important;
  }

  @media (max-width: 390px) and  (max-height: 800px) {
    width: 330px !important;
  }
`

export const BtnStack = styled(Stack)`
  width: 60%;
  height: 35px;
`

export const BtnStyled = styled(Stack)`
  font-size: 20px;
  font-weight: 100;
  height: 40px;
  border-radius: 10px;
  background: ${COLORS.gold};
  transition: background 0.3s;
  border: 1px solid black;

  &:hover {
    background: ${COLORS.gold_hover};
  }
`

export const TextBody = styled(Text)`
  font-size: 15px;
  font-weight: 700;
  text-shadow: 0 0 2px yellow;
`

export const TextHeader = styled(Text)`
  font-size: 25px;
  font-weight: 700;
  text-shadow: 0 0 3px yellow;
`

export const TextHeader2 = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  text-shadow: 0 0 3px yellow;
`

export const TextStack = styled(Stack)`
  margin: 20px 0;
  gap: 0 !important;
`
