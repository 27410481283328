import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getComments,
  getNotificationMessage,
  getPosts,
  likePost,
  setBlogParent,
  setComments,
  setNotificationSectionPage,
  subscribeToProfile
} from 'store'
import {
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import {
  NotificationSections,
  PENDING,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import { motion } from 'framer-motion'
import {
  isNotMobile,
  postTypeStatus
} from 'utils/helpers'
import { TextEditorReader } from 'components/UserSection/ProfileBlog/BlogList/TextEditorReader'
import { LikeButtonAnimation } from './styled'

const MotionStack = motion(Stack)
const MotionBtn = motion(Button)

export const BlogList = ({
  activeProfile,
  getPostsStatus,
  posts,
  postsOption,
  profileItem,
  subscribeToProfileStatus,
  setBlogSection, // Only used on marker popup
  widen = false
}) => {
  const dispatch = useDispatch()
  const [hideText, setHideText] = useState([])
  const [animate, setAnimate] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [oldPostsCount, setOldPostsCount] = useState(0)
  const [loadSubsId, setLoadSubsId] = useState(0)
  const observer = useRef()
  const secondToLastItem = useRef(null)

  const hideTextMap = (id) => {
    setHideText((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const animateLike = (id) => {
    setAnimate((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))

    setTimeout(() => {
      setAnimate((prevState) => ({
        ...prevState,
        [id]: !prevState[id]
      }))
    }, 500)
  }

  const noPostsMessage = () => {
    let msg = 'Post not existing'

    if (postsOption.type === 'marker') {
      msg = 'This profile doesn\'t have any posts'
    } else if (postsOption.type === 'affinity') {
      msg = 'There are no posts for this community'
    } else if (postsOption.type === 'subscribed') {
      msg = 'This user doesn\'t have any posts'
    } else if (postsOption.type === 'friends') {
      msg = 'You either doesn\'t have friends yet or your friends doesn\'t have any posts'
    } else if (postsOption.type === 'single') {
      msg = 'This post might be deleted or not existing'
    }

    return msg
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      // The "10" is the limit set on the backend DB query
      if (entries[0].isIntersecting && posts.length - oldPostsCount >= 10) {
        observer.current.unobserve(secondToLastItem.current)
        setLoadingMore(true)
        setOldPostsCount(posts.length)
        dispatch(getPosts({
          type: postsOption.type,
          ...(postsOption.type === 'affinity' ? { id: postsOption.id } : {}),
          ...(postsOption.type === 'marker' ? { id: profileItem.id } : {}),
          ...(postsOption.type === 'subscribed' ? { id: postsOption.id } : {}),
          lastItemId: posts.at(-1).id
        }))
      }
    })

    const currentObserver = observer.current
    if (secondToLastItem.current && posts.length - oldPostsCount >= 10) {
      currentObserver.observe(secondToLastItem.current)
    }

    setLoadingMore(false)

    return () => {
      if (secondToLastItem.current) {
        observer.current.disconnect()
      }
    }
  }, [posts])

  useEffect(() => {
    if (subscribeToProfileStatus === SUCCEEDED) {
      setLoadSubsId(0)
    }
  }, [subscribeToProfileStatus])

  return (
    <Stack
      margin={
        postsOption.type === 'marker'
        || isNotMobile()
          ? '0'
          : '16px 0'
      }
      gap="16px 0"
    >
      {
        getPostsStatus === PENDING && !posts.length
          ? (
            <Stack marginTop="100px">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
      {
        posts && posts.length
          ? posts.map((item, index) => {
            let profilePicture = ''
            let fullName = ''

            if (profileItem && postsOption.type === 'marker') {
              profilePicture = profileItem.profile_picture ?? ImagesPng.DefaultImage
              fullName = profileItem.full_name ?? ImagesPng.DefaultImage
            } else {
              profilePicture = item.profile_picture ?? ImagesPng.DefaultImage
              fullName = item.full_name ?? ImagesPng.DefaultImage
            }

            const widenFormat = () => {
              if (
                activeProfile?.id !== profileItem?.id
                && activeProfile?.id !== item.profile_id
              ) {
                if (
                  window.innerWidth > 400
                  && fullName.length < 13
                ) {
                  return true
                }

                if (
                  isNotMobile()
                  && widen
                ) {
                  return true
                }

                if (
                  fullName.length < 12
                  && window.innerWidth <= 400
                ) {
                  return true
                }
              }

              return false
            }

            const subscribeBtn = () => {
              if (
                subscribeToProfileStatus === PENDING
                && loadSubsId === item?.profile_id
              ) {
                return (
                  <LoadingSpinier
                    width="12px"
                    height="12px"
                    marginTop="3px"
                  />
                )
              }

              if (item?.isSubscribed) {
                return 'Unsubscribe'
              }

              return 'Subscribe'
            }

            return (
              <MotionStack
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 60
                }}
                ref={posts.length - 2 === index ? secondToLastItem : null}
                backgroundColor="rgb(229, 229, 229)"
                width="96%"
                borderRadius="3px"
                border="solid 1px black"
                padding="10px"
                display="flex"
                justifyContent="flex-start"
                gap="1rem"
                margin="0 2%"
              >
                <Stack
                  alignItems="center"
                  display="inline"
                  width="auto"
                  justifyContent="flex-start"
                  marginLeft="auto"
                  marginRight="auto"
                  sx={{
                    '@media (max-width: 473px)': {
                      marginTop: '10px'
                    }
                  }}
                >
                  <Image
                    boxSize="55px"
                    position="relative"
                    objectFit="cover"
                    src={profilePicture}
                    alt="User Image"
                    borderRadius="full"
                    display="inline-flex"
                    verticalAlign="bottom"
                    sx={{
                      '@media (max-width: 473px)': {
                        boxSize: '50px'
                      }
                    }}
                  />
                  <Text
                    textAlign={widenFormat() ? 'center' : ''}
                    display="inline-flex"
                    marginLeft="10px"
                    fontSize={widenFormat() ? '14px' : '16px'}
                    lineHeight="28px"
                    wordBreak="break-word"
                    margin="10px 0 0 10px !important"
                    sx={{
                      '@media (max-width: 473px)': {
                        fontSize: '13px',
                        lineHeight: '25px'
                      }
                    }}
                  >
                    {fullName}
                    <br />
                    { postTypeStatus(item) }
                  </Text>
                  {
                    widenFormat()
                      ? (
                        <Button
                          onClick={() => {
                            setLoadSubsId(profileItem?.id && postsOption.type === 'marker' ? profileItem?.id : item?.profile_id)
                            dispatch(subscribeToProfile(profileItem?.id && postsOption.type === 'marker' ? profileItem?.id : item?.profile_id))
                          }}
                          width="70px"
                          height="30px"
                          fontSize="11px"
                          fontWeight="400"
                          margin="25px 0 0 10px !important"
                          backgroundColor={COLORS.gold}
                          _hover={{
                            background: COLORS.gold_hover
                          }}
                          pointerEvents={subscribeToProfileStatus === PENDING ? 'none' : 'auto'}
                        >
                          {subscribeBtn()}
                        </Button>
                      ) : ''
                  }
                </Stack>

                {
                  widenFormat()
                  || activeProfile?.id === item.profile_id
                    ? ''
                    : (
                      <Button
                        onClick={() => {
                          setLoadSubsId(profileItem?.id && postsOption.type === 'marker' ? profileItem?.id : item?.profile_id)
                          dispatch(subscribeToProfile(profileItem?.id && postsOption.type === 'marker' ? profileItem?.id : item?.profile_id))
                        }}
                        width="70px"
                        height="30px"
                        fontSize="11px"
                        fontWeight="400"
                        margin="0 auto !important"
                        backgroundColor={COLORS.gold}
                        _hover={{
                          background: COLORS.gold_hover
                        }}
                        pointerEvents={subscribeToProfileStatus === PENDING ? 'none' : 'auto'}
                      >
                        {subscribeBtn()}
                      </Button>
                    )
                }

                <Stack
                  display="flex"
                  justifyContent="center"
                  direction="row"
                >
                  <MotionBtn
                    initial={{
                      background: hideText[item.id]
                        ? COLORS.lightGoldGradientLeftToRight
                        : COLORS.darkGrayGradientBotToTop
                    }}
                    animate={{
                      background: hideText[item.id]
                        ? COLORS.darkGrayGradientBotToTop
                        : COLORS.lightGoldGradientLeftToRight
                    }}
                    whileTap={{ scale: 0.8 }}
                    border="solid 1px rgb(119, 119, 119)"
                    padding="2px 30px"
                    fontSize={widen ? '23px' : '19px'}
                    width="95%"
                    borderRadius="5px"
                    align="center"
                    letterSpacing=".15px"
                    fontWeight="400"
                    _hover={{
                      background: ''
                    }}
                    _active={{
                      background: ''
                    }}
                    onClick={() => hideTextMap(item.id)}
                    display="block"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {item.title}
                  </MotionBtn>
                </Stack>

                <MotionStack
                  initial={{ x: 0 }}
                  animate={{
                    height: hideText[item.id] ? '0px' : 'auto',
                    opacity: hideText[item.id] ? '0' : '1'
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 60
                  }}
                  direction="row"
                  justifyContent="center"
                  margin="-2.5px 0"
                  display="flex"
                >
                  {
                    item.affinities.length
                      ? item.affinities.map((e) => (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize={widen ? '15px' : '11px'}
                          margin="2.5px 0px"
                          overflow="hidden"
                        >
                          {e.title}
                        </Box>
                      ))
                      : (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize={widen ? '15px' : '11px'}
                          overflow="hidden"
                        >
                          No Affinities
                        </Box>
                      )
                  }
                </MotionStack>

                <MotionStack
                  initial={{ x: 0 }}
                  animate={{
                    height: hideText[item.id] ? '0px' : 'auto',
                    opacity: hideText[item.id] ? '0' : '1'
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 60
                  }}
                  display="block"
                  overflow="hidden"
                >
                  <TextEditorReader
                    item={item}
                    widen={widen}
                  />
                </MotionStack>

                <Stack
                  direction="row"
                  borderTop="solid 1px black"
                  width="calc(100% + 20px)"
                  padding={postsOption.type === 'marker' ? '0' : '3.5px 0px'}
                  justifyContent="space-around"
                  marginBottom="-10px"
                  marginLeft="-10px"
                >
                  <LikeButtonAnimation className={animate[item.id] ? 'animate' : ''}>
                    <Stack
                      as="button"
                      onClick={() => {
                        if (activeProfile?.id) {
                          animateLike(item.id)
                          dispatch(likePost(item.id))
                        } else {
                          dispatch(
                            getNotificationMessage({
                              type: REJECTED,
                              message: 'You do not have an active profile. Please setup your profile first.',
                              isEditProfile: true
                            })
                          )
                        }
                      }}
                    >
                      <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                    </Stack>
                    <Text>{item.likes}</Text>
                  </LikeButtonAnimation>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Stack
                      onClick={() => {
                        if (activeProfile) {
                          dispatch(setComments([]))
                          dispatch(setBlogParent([item]))
                          dispatch(getComments({
                            type: 'post',
                            post_id: item.id
                          }))

                          try {
                            if (
                              document.querySelector('#notification-drawer-header').offsetWidth * 3 >= document.querySelector('body').offsetWidth
                            ) {
                              dispatch(setNotificationSectionPage(
                                NotificationSections.BLOG_COMMENTS_FOR_POST
                              ))
                            }
                          } catch {
                            //
                          }

                          if (
                            postsOption.type === 'marker'
                            || (
                              isNotMobile()
                              || postsOption.type === 'single'
                            )
                          ) {
                            setBlogSection('comments')
                          }
                        } else {
                          dispatch(
                            getNotificationMessage({
                              type: REJECTED,
                              message: 'You do not have an active profile. Please setup your profile first.',
                              isEditProfile: true
                            })
                          )
                        }
                      }}
                    >
                      <CommentIcon />
                    </Stack>
                    <Text>{item.comments}</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <RepostIcon />
                    <Text>0</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Stack
                      as="button"
                      onClick={() => navigator.clipboard.writeText(window.location.origin + '/?post-id=' + item?.id)}
                    >
                      <ShareIcon />
                    </Stack>
                    <Text>0</Text>
                  </Stack>
                </Stack>
              </MotionStack>
            )
          })
          : (
            <Stack
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 60
              }}
            >
              {
                getPostsStatus === PENDING && !posts.length
                  ? ''
                  : (
                    <Text
                      textAlign="center"
                      backgroundColor="rgb(229, 229, 229)"
                      borderRadius="3px"
                      border="solid 1px black"
                      padding="10px"
                      margin="0 2% !important"
                      fontSize={widen ? '17px' : '13px'}
                    >
                      { noPostsMessage() }
                    </Text>
                  )
              }
            </Stack>
          )
      }
      {
        loadingMore && getPostsStatus === PENDING
          ? (
            <Stack margin="75px 0 60px 0">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
    </Stack>
  )
}
