import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  ArrowIcon,
  ResizeIcon,
  ShrinkIcon
} from 'assets/icons'
import {
  PENDING,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import { isAuth, isNotMobile } from 'utils/helpers'
import { AskToSignUpModal } from 'components/Content/AskToSignUpModal'
import { BlogList } from 'components/Notifications/CommunityBlogs/BlogList'
import { Comments } from 'components/Notifications/CommunityBlogs/Comments'
import { COLORS } from 'utils/styles'
import {
  authSelectors,
  usersSelectors,
  setOpenPopupBlogs,
  getNotificationMessage,
  setComments,
  getComments,
  setBlogParent,
  setHideNotification
} from 'store'
import {
  StyledDrawerContent
} from './styled'

export const BlogModal = () => {
  const dispatch = useDispatch()
  const {
    activeProfile,
    blogParent,
    comments,
    commentsOption,
    getCommentsStatus,
    getPostsStatus,
    getUserProfileStatus,
    postCommentsStatus,
    putCommentsStatus,
    posts,
    profileItem,
    openPopupBlogs,
    subscribeToProfileStatus,
    chosenNotifBlog,
    postsOption
  } = useSelector(usersSelectors)
  const { signInStatus } = useSelector(authSelectors)

  const drawerRef = useRef()

  const [blogSection, setBlogSection] = useState('posts')
  const [widen, setWiden] = useState(false)
  const [widenAnimation, setWidenAnimation] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const [askToSignUp, setAskToSignUp] = useState(false)

  const isLoggedIn = signInStatus === SUCCEEDED || isAuth()

  const shouldWiden = () => widen && widenAnimation && isNotMobile()

  const closeDrawer = () => {
    setWidenAnimation(false)
    setWiden(false)
    setAnimateOut(true)
    localStorage.removeItem('postId')
    dispatch(setHideNotification(false))
    setTimeout(() => {
      setAnimateOut(false)
      dispatch(setOpenPopupBlogs(false))
    }, 500)
  }

  const getDrawerHeight = () => (window.innerHeight - 120) + 'px'

  const handleBlogBackBtn = () => {
    if (blogParent.length > 1) {
      if (getCommentsStatus === PENDING) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Please finish loading first before returning to previous blog post or comment',
          isEditProfile: true
        }))
      } else {
        const parentData = blogParent[blogParent.length - 1]

        dispatch(setComments([]))
        dispatch(getComments({
          type: parentData.parent_type,
          ...(parentData.parent_type === 'post' ? { post_id: parentData.post_id } : { comment_id: parentData.parent_comment_id })
        }))

        dispatch(setBlogParent(blogParent.slice(0, -1)))
      }
    } else {
      setBlogSection('posts')
    }
  }

  const blogModalTitle = () => {
    if (getPostsStatus === PENDING) {
      return ''
    }

    if (chosenNotifBlog?.title) {
      return chosenNotifBlog?.title
    }

    return posts[0]?.full_name ? posts[0]?.full_name + "'s Blog" : ''
  }

  useEffect(() => {
    if (
      isLoggedIn
      && getUserProfileStatus === SUCCEEDED
      && localStorage.getItem('postId')
    ) {
      setAskToSignUp(false)

      try {
        document.querySelector('button[name="profile-pane-back-btn"]').click()
      } catch {
        //
      }
    }
  }, [isLoggedIn, getUserProfileStatus])

  useEffect(() => {
    if (getPostsStatus === PENDING) {
      setBlogSection('posts')
    }
  }, [getPostsStatus])

  useEffect(() => {
    setTimeout(() => setWidenAnimation(true), 500)
  }, [])

  return (
    <Drawer
      id="blog-drawer"
      finalFocusRef={drawerRef}
      isOpen={openPopupBlogs}
      onClose={() => {
        if (isLoggedIn) {
          closeDrawer()
        }
      }}
      placement="bottom"
      size="sm"
      closeOnEsc={false}
      closeOnOverlayClick={isLoggedIn}
      boxShadow="2xl"
      blockScrollOnMount={false}
    >
      <StyledDrawerContent
        initial={{
          width: shouldWiden() && widenAnimation ? '365px' : '',
          ...(animateOut ? { height: getDrawerHeight(), opacity: 1 } : { height: '0px', y: 0, opacity: 1 })
        }}
        animate={{
          width: shouldWiden() && widenAnimation ? '70svw' : '365px',
          ...(animateOut ? { height: '0px', opacity: 0 } : { height: getDrawerHeight() })
        }}
        transition={{
          type: 'spring',
          stiffness: 190,
          damping: 22
        }}
        gap={blogSection === 'posts' ? '1em' : 0}
      >
        <AskToSignUpModal
          dynamicOpenModal={askToSignUp}
          posts={posts}
        />

        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          p="0px 5px"
          background={COLORS.goldGradientRightToLeft}
          minHeight="48px"
          borderRadius="10px 10px 0 0"
          marginTop="-1px"
          position="sticky"
          top="-0.2"
          zIndex={askToSignUp ? 5 : 1000}
          onClick={() => {
            if (!isLoggedIn && !askToSignUp) {
              setAskToSignUp(true)
            }
          }}
          cursor={isLoggedIn ? 'auto' : 'pointer'}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setWidenAnimation(true)
              setWiden(!widen)
              dispatch(setHideNotification(!widen))
            }}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            icon={<ResizeIcon />}
            id="resize-button"
            outline="none"
            boxShadow="none"
            display={widen ? 'none' : 'flex'}
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setWiden(!widen)
              dispatch(setHideNotification(!widen))
            }}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            icon={<ShrinkIcon />}
            id="shrink-button"
            outline="none"
            boxShadow="none"
            display={widen ? 'auto' : 'none'}
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />

          <Text
            maxWidth="70%"
            fontSize="24px"
            textAlign="center"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            { blogModalTitle() }
          </Text>

          <IconButton
            onClick={() => {
              if (isLoggedIn && !askToSignUp) {
                if (blogSection === 'posts') {
                  closeDrawer()
                } else {
                  handleBlogBackBtn()
                }
              }
            }}
            _hover={{ background: COLORS.gold_hover }}
            background="unset"
            transform={blogSection === 'comments' ? 'rotate(180deg)' : 'rotate(-90deg)'}
            icon={<ArrowIcon height="15px" />}
            id="blog-modal-back-button"
            outline="none"
            boxShadow="none"
            css={{
              ':focus': {
                outline: 'none',
                boxShadow: 'none'
              }
            }}
          />
        </Stack>

        <Stack
          id="force-close-blog-drawer"
          display="none"
          onClick={() => closeDrawer()}
        />

        <Stack
          onClick={() => {
            if (!isLoggedIn && !askToSignUp) {
              setAskToSignUp(true)
            }
          }}
        >
          {
            isLoggedIn
            && !askToSignUp
              ? ''
              : (
                <Box
                  position="fixed"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  backdropFilter={askToSignUp ? 'blur(2px)' : ''}
                  zIndex="999"
                  cursor={askToSignUp ? '' : 'pointer'}
                />
              )
          }

          {
            blogSection === 'comments'
              ? (
                <Comments
                  activeProfile={activeProfile}
                  blogParent={blogParent}
                  comments={comments}
                  commentsOption={commentsOption}
                  getCommentsStatus={getCommentsStatus}
                  postCommentsStatus={postCommentsStatus}
                  putCommentsStatus={putCommentsStatus}
                  postsOption={postsOption}
                  widen={widen}
                />
              ) : (
                <BlogList
                  activeProfile={activeProfile}
                  getPostsStatus={getPostsStatus}
                  posts={posts}
                  postsOption={postsOption}
                  profileItem={profileItem}
                  chosenNotifBlog={chosenNotifBlog}
                  subscribeToProfileStatus={subscribeToProfileStatus}
                  setBlogSection={setBlogSection}
                  widen={widen}
                />
              )
          }
        </Stack>
      </StyledDrawerContent>
    </Drawer>
  )
}
