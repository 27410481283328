import { useState } from 'react'
import {
  Box,
  Image,
  Modal,
  ModalBody,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import { motion } from 'framer-motion'
import {
  BtnStack,
  BtnStyled,
  ModalContentStyled,
  TextBody,
  TextHeader,
  TextHeader2,
  TextStack
} from './styled'

const MotionBox = motion(Box)

export const AskToSignUpModal = ({
  dynamicOpenModal,
  posts
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <Modal
      isCentered
      isOpen={dynamicOpenModal}
      onClose={onClose}
      bg={COLORS.whiteBlackGradientTopToBot}
    >
      <ModalContentStyled>
        <ModalBody
          margin="0"
          padding="0 10px"
          textAlign="center"
          overflow="hidden"
          maxHeight="65svh"
          overflowY="scroll"
          sx={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <MotionBox
            initial={{ scaleY: copiedToClipboard ? 1 : 0 }}
            animate={{
              scaleY: copiedToClipboard ? 0 : 1,
              ...(copiedToClipboard && { height: 0 })
            }}
            transition={{
              type: 'tween',
              duration: 0.5
            }}
            height="auto"
            margin={copiedToClipboard ? 0 : '20px 0'}
          >
            <Stack margin="20px 0" gap="0 !important">
              <TextHeader>You are wanted here.</TextHeader>
              <TextHeader>Please join our community.</TextHeader>
            </Stack>
          </MotionBox>

          <Stack alignItems="center">
            <Image
              src={ImagesPng.Logo}
              alignItems="center"
              w={16}
              h={16}
            />
          </Stack>

          <MotionBox
            display={copiedToClipboard ? 'auto' : 'none'}
            height={copiedToClipboard ? '' : 0}
            opacity={copiedToClipboard ? 1 : 0}
            position="relative"
          >
            <TextStack>
              <TextHeader2>Article link copied to clipboard.</TextHeader2>
              <TextHeader2>Thanks for sharing!</TextHeader2>
            </TextStack>
          </MotionBox>

          <MotionBox
            initial={{
              scaleY: copiedToClipboard ? 1 : 0,
              height: copiedToClipboard ? '' : 0
            }}
            animate={{
              scaleY: copiedToClipboard ? 0 : 1,
              height: copiedToClipboard ? 0 : ''
            }}
            transition={{
              type: 'tween',
              duration: 0.5
            }}
            margin={copiedToClipboard ? 0 : '20px 0'}
          >
            <TextStack>
              <TextBody>On Connector you can find</TextBody>
              <TextBody>friends, dates, professional opportunities,</TextBody>
              <TextBody>and even events.</TextBody>
            </TextStack>

            <TextStack>
              <TextBody>It all takes place on a map,</TextBody>
              <TextBody>because real life happens</TextBody>
              <TextBody>in real world.</TextBody>
            </TextStack>

            <TextStack>
              <TextBody>Create your new network of people</TextBody>
              <TextBody>who share your hobbies, values, and goals</TextBody>
              <TextBody>with searchable keywords called affinities.</TextBody>
            </TextStack>

            <TextStack>
              <TextBody>Receive Connector Coin monthly</TextBody>
              <TextBody>in revenue sharing pools</TextBody>
              <TextBody>just for being an active member.</TextBody>
            </TextStack>

            <BtnStack margin="20px auto 10px auto">
              <BtnStyled
                as="button"
                onClick={() => document.querySelector('.profile-icon').click()}
              >
                Get Connected
              </BtnStyled>
            </BtnStack>

            <BtnStack margin="10px auto 20px auto">
              <BtnStyled
                as="button"
                onClick={() => {
                  setCopiedToClipboard(true)
                  navigator.clipboard.writeText(window.location.origin + '/?post-id=' + posts[0]?.id)
                  setTimeout(() => setCopiedToClipboard(false), 3000)
                }}
              >
                Share Article
              </BtnStyled>
            </BtnStack>
          </MotionBox>
        </ModalBody>
      </ModalContentStyled>
    </Modal>
  )
}
